import Cookies from '../classes/cookies';

// Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
export default function manageCookies() {
  Cookies.waitForElementToDisplay('#s-rall-bn', () => {
    Cookies.updateCookies(false);

    // Boutons accepter, refuser et sauvegarder dans la sidebar
    document.querySelector('#s-all-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-rall-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-sv-bn').addEventListener('click', Cookies.updateCookies);

    // Si le cookie cc_cookie_byscuit existe les boutons #c-t-bn et #c-p-bn sont pas init
    const btnDeclinePopup = document.querySelector('#c-t-bn');
    if (btnDeclinePopup) {
      // Boutons accepter et refuser dans le popup
      document.querySelector('#c-p-bn').addEventListener('click', Cookies.updateCookies);
      btnDeclinePopup.addEventListener('click', Cookies.updateCookies);
    }
  }, 1000, 10000);
}

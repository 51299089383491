// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------

export function swiperSecondSection(options = {}) {

  // Déclaration des propriétés par défaut
  options.slider === undefined ? options.slider = '[data-swiper="second-section"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="second-section-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="second-section-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="second-section-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="second-section-next"]' : options.next

  // Si il y a plus qu'une slide
  if (document.querySelectorAll(options.slide).length > 1) {

    // Création du slider
    window['second-section'] = new Swiper(options.slider, {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: options.prev,
        nextEl: options.next
      },
    })
  }
}

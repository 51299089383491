// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------

export function swiperThreeImagesCarousel(options = {}) {

  // Déclaration des propriétés par défaut
  options.slider === undefined ? options.slider = '[data-swiper="three-images-carousel"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="three-images-carousel-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="three-images-carousel-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="three-images-carousel-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="three-images-carousel-next"]' : options.next

  // Si il y a plus qu'une slide
  if (document.querySelectorAll(options.slide).length > 1) {

    // Création du slider
    window['three-images-carousel'] = new Swiper(options.slider, {
      loop: true,
      speed: 500,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 'auto',
      loopedSlides: 3,
      centeredSlides: true,
      navigation: {
        prevEl: options.prev,
        nextEl: options.next
      },
    })
  }
}

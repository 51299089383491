// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'
// ---------------------------------------------------------------------------

export function swiperPartnersCarousel(options = {}) {
  // Déclaration des propriétés par défaut
  options.slider === undefined ? options.slider = '[data-swiper="partners-carousel"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="partners-carousel-slide"]' : options.slide

  // Si il y a plus qu'une slide
  if (document.querySelectorAll(options.slide).length > 1) {

    // Création du slider
    window['partners-carousel'] = new Swiper(options.slider, {
      loop: true,
      speed: 500,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 'auto',
      loopedSlides: 20,
      loopedSlidesLimit: false,
      spaceBetween: 50,
      breakpoints: {
        700: {
          spaceBetween: 40
        }
      },
      on: {
        beforeInit: function(){
          const swiperContainer = document.querySelector('.partners-carousel__swiper-container')
          const swiperWrapper = document.querySelector('.partners-carousel__swiper-wrapper')
          const swiperSlides = swiperContainer.querySelectorAll('.swiper-slide')

          const compareWidth = () => {
            const windowWidth = window.innerWidth

            let i
            let j
            let totalWidth= 0

            for (i = 0; i < swiperSlides.length; i += 1) {
              const swiperSlide = swiperSlides[i]

              totalWidth += swiperSlide.offsetWidth
            }

            if (totalWidth < windowWidth) {
              for (j = 0; j < swiperSlides.length; j += 1) {
                const swiperSlide = swiperSlides[j]
                const clone = swiperSlide.cloneNode(true)

                swiperWrapper.appendChild(clone)
              }
            }
          }

          compareWidth()

          OBSERVER.add({
            name: 'addClones',
            event: 'resize',
            function: compareWidth
          })

          OBSERVER.on('addClones')
        }
      }
    })
  }

  specialHoverFooter()

  // Effet d'hover de la zone des partenaires (footer)
  function specialHoverFooter() {
    let hoverElement
    let allElements
    let i
    let element

    const onHoverIn = (e) => {
      hoverElement = e.target

      allElements = document.querySelectorAll('.js-hover')

      for(i=0;i<allElements.length;i++) {
        element = allElements[i]
        element.classList.add('no-hover-element')
      }

      hoverElement.classList.remove('no-hover-element')
    }

    const onHoverOut = (e) => {
      hoverElement = e.target

      allElements = document.querySelectorAll('.js-hover')

      for(i=0;i<allElements.length;i++) {
        element = allElements[i]
        element.classList.remove('no-hover-element')
      }
    }

    OBSERVER.add({
      name: 'hover',
      event: 'mouseenter',
      target: '.js-hover',
      function: onHoverIn
    })
    OBSERVER.add({
      name: 'hover',
      event: 'mouseleave',
      target: '.js-hover',
      function: onHoverOut
    })

    OBSERVER.on('hover')
  }
}

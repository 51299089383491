import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset, isVisible } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })

  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })

  return animation.finished
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}

export function changeSelect(select) {
  $(select).next('.tail-select').find('.dropdown-option').on('click', () => {
    $(select).trigger('change')
  })
}

// Fait disparaître le bouton calendrier si le footer est visible
export function hideCalendarButton() {
  let button = document.querySelector('.layout__sticky-button')
  let footer = document.querySelector('.footer')

  function checkIfFooterIsVisible() {
    if(isVisible(footer)) {
      button.classList.add('hide')
    } else {
      button.classList.remove('hide')
    }
  }

  OBSERVER.add({
    name: 'checkFooterVisibility',
    event: 'scroll',
    function: checkIfFooterIsVisible
  })

  OBSERVER.on('checkFooterVisibility')
}

// data-font-size-based-on-caracters-count="{font-size}:min-max,{font-size}:min-max"
export function fontSizeBasedOnCaracters() {
  const elements = document.querySelectorAll('[data-font-size-based-on-caracters-count]')
  const elementsLength = elements.length

  let i
  let j

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i]
    const elementTextLength = element.textContent.length
    const fontSizesDataAttributeValue = element.getAttribute('data-font-size-based-on-caracters-count')
    const fontSizes = fontSizesDataAttributeValue.split(',')
    const fontSizesLength = fontSizes.length

    for (j = 0; j < fontSizesLength; j += 1) {
      const fontSizeAndCount = fontSizes[j]
      const fontSizeAndCountArray = fontSizeAndCount.split(':')

      const fontSize = fontSizeAndCountArray[0]

      const countMinAndMax = fontSizeAndCountArray[1]
      const countMinAndMaxArray = countMinAndMax.split('-')
      const min = countMinAndMaxArray[0]
      const max = countMinAndMaxArray[1]

      if (elementTextLength >= min && elementTextLength <= max) {
        element.classList.add(`u-font-size-${fontSize}`)
      }
    }
  }
}

import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, changeSelect, clickToScrollToBlock, fontSizeBasedOnCaracters, hideCalendarButton, ogContent } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, inputsAndTextareaLabel, clearInput, tailSelect } from './functions/form.js'
import { overlayDropdown, overlayMenu, overlayPopup, overlaySearch, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formExample, formJobs, formSuccess } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { hundredVH, removeHoverOnMobile } from './functions/helper.js'
import { faqFilters } from './functions/faq.js'
import { swiperThreeImagesCarousel } from './functions/three-images-carousel.js'
import { swiperTextAndImageCarousel } from './functions/text-and-image-carousel.js'
import { swiperPartnersCarousel } from './functions/partners-carousel.js'
import { swiperHomeBanner } from './functions/home-banner.js'
import { swiperSecondSection } from './functions/second-section.js'
import { swiperThirdSection } from './functions/third-section.js'
import { swiperAlert } from './functions/alert.js'
import { swiperCareerPageTitle } from './functions/career.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'
import manageCookies from './functions/manageCookies'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()
    hundredVH()
    overlayDropdown()
    overlayMenu()
    overlayShare()
    overlaySearch()
    overlayPopup()
    backendKeyBind()
    SCROLLFIRE.init()
    removeHoverOnMobile()
    clickToScrollToBlock({ selector: '[data-js="scroll-to-top"]' })
    swiperPartnersCarousel()
    swiperAlert()
    inputsAndTextareaLabel()
    clearInput()
    ShareButtons.update()
    manageCookies()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          changeSelect('#catFilterSelect')
          formExample()
          swiperCareerPageTitle()
          swiperThreeImagesCarousel()
          swiperTextAndImageCarousel()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section1"]', scrollTo: '[data-js="section1"]' })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section2"]', scrollTo: '[data-js="section2"]' })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section3"]', scrollTo: '[data-js="section3"]' })
          formJobs()

          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner()
          swiperSecondSection()
          swiperThirdSection()
          hideCalendarButton()
          fontSizeBasedOnCaracters()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          formContact()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="contact-form"]' })
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect()
          changeSelect('#catFilterSelect')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'newsDetailsView',
        beforeEnter() {
          dynamicPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'promoView',
        beforeEnter() {
          new Accordions({ scrollToAccordion: false })
          formsPackage()
          masksPackage()
          dynamicPackage()
          changeSelect('#catFilterSelect')
          formExample()
          swiperCareerPageTitle()
          swiperThreeImagesCarousel()
          swiperTextAndImageCarousel()
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section1"]', scrollTo: '[data-js="section1"]' })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section2"]', scrollTo: '[data-js="section2"]' })
          clickToScrollToBlock({ selector: '[data-js="scroll-to-section3"]', scrollTo: '[data-js="section3"]' })
          formJobs()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          changeSelect('#catFilterSelect')
          changeSelect('#establishmentFilterSelect')
          dynamicPackage()
          formJobs()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'jobsDetailsView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          formJobs()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'faqView',
        beforeEnter() {
          tailSelect()
          changeSelect('#faqFilterSelect')
          faqFilters()
          dynamicPackage()
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'todayView',
        beforeEnter() {
          new Accordions()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {
          window['lazyload'].destroy()
        }
      }
    ]
  })
}

import $ from 'jquery'
import Overlay from '../vendor/overlay.js'
import { Accordions } from '../classes/accordions'
import { OBSERVER } from './../main.js'
import ShareButtons from 'share-buttons/dist/share-buttons'

export function overlayShare() {

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
        switch: '[data-js="toggle-overlay-menu"], [data-js="toggle-search"], [data-js="toggle-search"], [data-js="trigger-overlay-dropdown"]'
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  $('.js-copy-share').on('click', function() {
    setTimeout(() => {
      alert('L’adresse URL a bien été copiée dans votre presse-papier.')
    }, 300)
  })

  $('.js-share').on('click', function(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  })

  $('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  })
}

export const overlaySearch = () => {
  new Overlay({
    name: 'search',
    events: {
      openComplete: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-search"]',
        close: '[data-js="close-search"]',
        switch: '[data-js="trigger-overlay-dropdown"], [data-js="toggle-overlay-menu"], .js-share'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
    }
  }).init()

  const onFocus = () => { document.getElementById('inputFocus').focus() }
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onFocus })
  OBSERVER.on('overlaySearch')
}

export function overlayPopup() {
  let popup = new Overlay({
    name: 'popup',
    create: {
      background: false
    },
    timeout: {
      delay: 300,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup'
      }
    },
    options: {
      speed: 800,
    }
  })

  popup.init()
}

export const overlayDropdown = () => {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '.js-share, [data-js="toggle-overlay-menu"], [data-js="toggle-search"]'
      }
    },
    mouseover: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="hover-close-overlay-dropdown"]',
      }
    },
    animations: {
      styles: [{
        property: 'height',
        value: 'dataset',
        easing: 'easeInOutQuart'
      }]
    },
    options: {
      speed: 1000,
      goToSelector: 'html, body'
    }
  }).init()

  specialHoverDropdown()

  function specialHoverDropdown() {
    let hoverElement
    let allElements
    let i
    let element

    const onHoverIn = (e) => {
      hoverElement = e.target

      allElements = document.querySelectorAll('.js-hoverLink')

      for(i=0;i<allElements.length;i++) {
        element = allElements[i]
        element.classList.add('no-hover-element')
      }

      hoverElement.classList.remove('no-hover-element')
    }

    const onHoverOut = (e) => {
      hoverElement = e.target

      allElements = document.querySelectorAll('.js-hoverLink')

      for(i=0;i<allElements.length;i++) {
        element = allElements[i]
        element.classList.remove('no-hover-element')
      }
    }

    OBSERVER.add({
      name: 'hover',
      event: 'mouseenter',
      target: '.js-hoverLink',
      function: onHoverIn
    })
    OBSERVER.add({
      name: 'hover',
      event: 'mouseleave',
      target: '.js-hoverLink',
      function: onHoverOut
    })

    OBSERVER.on('hover')
  }

  // Ajuster la taille de chaque sous-section pour éviter les bugs lorsque la fenêtre est redimensionnée
  const setHeightSubSections = () => {
    let i, j, offsetHeight = 0, height = 0
    const section = document.querySelectorAll('.js-dropdown-section')
    const sectionLength = section.length

    for (i=0; i<sectionLength; i++) {
      const subSection = section[i].children
      const subSectionLength = subSection.length

      for (j=0; j<subSectionLength; j++) {
        subSection[j].style.height = ''
        offsetHeight = subSection[j].offsetHeight
        if (offsetHeight > height)
          height = offsetHeight
      }

      for (j=0; j<subSectionLength; j++)
        subSection[j].style.height = `${height}px`

      height = 0
      offsetHeight = 0
    }
  }

  setHeightSubSections()

  OBSERVER.add({ name: 'setHeightSubSections', event: 'resize', function: setHeightSubSections, target: 'window' })
  OBSERVER.on('setHeightSubSections')
}

export const overlayMenu = () => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '.js-share, [data-js="toggle-search"]'
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  })

  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  })

  const onClose = () => {
    mainLevel.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.on('overlayMenu')
}

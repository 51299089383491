// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------

export function swiperCareerPageTitle(options = {}) {

  // Déclaration des propriétés par défaut
  options.slider === undefined ? options.slider = '[data-swiper="career-page-title"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="career-page-title-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="career-page-title-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="career-page-title-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="career-page-title-next"]' : options.next

  // Si il y a plus qu'une slide
  if (document.querySelectorAll(options.slide).length > 1) {

    // Création du slider
    window['career-page-title'] = new Swiper(options.slider, {
      loop: true,
      speed: 500,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 'auto',
      spaceBetween: 20,
      loopedSlides: 3,
      centeredSlides: true,
      navigation: {
        prevEl: options.prev,
        nextEl: options.next
      },
      breakpoints: {
        400: {
          spaceBetween: 30
        },
        500: {
          spaceBetween: 40
        },
        900: {
          spaceBetween: 60
        }
      }
    })
  }
}

import $ from 'jquery'
// import 'jquery.easing'

export function faqFilters() {

  if ($('#letterIndex')) {
    let alphabet = genCharArray('A', 'Z')

    $('#letterIndex a').on('click', function() {
      if(!($(this).hasClass('seeAll'))){
        let letter = this.id.replace('letter_', '')
        $('#letterIndex a').removeClass('active')
        $(this).addClass('active')
        for (let i = 0; i < alphabet.length; i++) {
          if(letter != alphabet[i]) {
            //{ duration: 800, specialEasing: { height: 'easeInOutCubic' }})
            $('#wrapper_' + alphabet[i]).animate({ height: 'hide' }, { duration: 800 })
            $('#wrapper_' + alphabet[i]).removeClass('active')
          } else {
            $('#wrapper_' + alphabet[i]).animate({ height: 'show' }, { duration: 800 })
            $('#wrapper_' + alphabet[i]).addClass('active')
          }
        }
      }
    })

    $('.seeAll').on('click', function() {
      $('#letterIndex a').removeClass('active')
      $('.seeAll').addClass('active')
      for (let i = 0; i < alphabet.length; i++) {
        $('#wrapper_' + alphabet[i]).animate({ height: 'show' }, { duration: 800 })
        $('#wrapper_' + alphabet[i]).addClass('active')
      }
    })
  }

  function genCharArray(charA, charZ) {
    let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0)
    for (; i <= j; ++i) {
      a.push(String.fromCharCode(i))
    }
    return a
  }
  
}